import React from 'react'
import styled from 'styled-components'
import GlobalStyle from './globalStyle'
import { ThemeProvider } from 'styled-components'
import seaShell from '../themes/seaShell'
import { Helmet } from 'react-helmet'
import Footer from './footer'

export default function Layout({ children }) {
	return (
		<ThemeProvider theme={seaShell}>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Brandon Yuen - Creative Developer</title>
				<meta name="viewport" content="width=device-width, minimum-scale=1, maximum-scale=1"></meta>
			</Helmet>
			<LayoutContainer>
				<GlobalStyle />
				{children}
			</LayoutContainer>
			<Footer />
		</ThemeProvider>
	)
}

const LayoutContainer = styled.div`
	margin: 0 10vw;

	@media (max-width: ${props => props.theme.sizes.minDesktop}) {
		margin: 0 5vw;
	}
`
