import Typography from 'typography'
import seaShell from '../themes/seaShell'

const typo = new Typography({
	baseFontSize: '18px',
	baseLineHeight: 1.666,
	headerFontFamily: [seaShell.fonts.big],
	bodyFontFamily: [seaShell.fonts.small]
})

export default typo
export const rhythm = typo.rhythm
