import { useStaticQuery, graphql } from 'gatsby'

export const useSocials = () => {
	const data = useStaticQuery(
		graphql`
			query {
				markdownRemark(fileAbsolutePath: { regex: "/(home)/" }) {
					frontmatter {
						socials {
							email
							linkedin_url
							github_url
						}
					}
				}
			}
		`
	)
	return data.markdownRemark.frontmatter.socials
}
