const seaShell = {
	colors: {
		primary: `#fffaf0`,
		secondary: `#000`
	},
	fonts: {
		big: 'Titillium Web Black',
		medium: 'Titillium Web Bold',
		small: 'Titillium Web Regular'
	},
	sizes: {
		minDesktopLarge: '1500px',
		minDesktop: '1200px',
		minMobile: '600px'
	}
}

export default seaShell
