import styled from 'styled-components'

const SmallParagraph = styled.div`
	font-family: ${props => props.theme.fonts.small};
	strong {
		font-family: ${props => props.theme.fonts.medium};
		font-size: 112.5%;
	}

	a {
		color: unset;
		text-decoration: underline;
	}
`

export default SmallParagraph
