import styled from 'styled-components'
import { rhythm } from '../utils/typography'

const BigTitle = styled.span`
	text-transform: uppercase;
	font-size: ${rhythm(2.5)};
	font-family: ${props => props.theme.fonts.big};
	display: inline-block;

	@media (max-width: ${props => props.theme.sizes.minDesktop}) {
		font-size: ${rhythm(2.3)};
	}
	@media (max-width: ${props => props.theme.sizes.minMobile}) {
		font-size: 11.5vw;
	}
`

export default BigTitle
