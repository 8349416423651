import React from 'react'
import styled from 'styled-components'
import { useSocials } from '../hooks/useSocials'
import { rhythm } from '../utils/typography'
import MediumParagraph from './mediumParagraph'
import Socials from './socials'

export default function Footer() {
	return (
		<FooterContainer>
			<FooterText>Contact me via Mail or LinkedIn</FooterText>
			<SocialsWrapper>
				<Socials color="light" />
			</SocialsWrapper>
		</FooterContainer>
	)
}

const FooterContainer = styled.div`
	margin-top: 5rem;
	width: 100%;
	padding: 5rem;
	background-color: ${props => props.theme.colors.secondary};
`
const FooterText = styled(MediumParagraph)`
	cursor: pointer;
	color: ${props => props.theme.colors.primary};
`

const SocialsWrapper = styled.div`
	font-size: ${rhythm(1.5)};

	@media (max-width: ${props => props.theme.sizes.minDesktop}) {
	}
	@media (max-width: ${props => props.theme.sizes.minMobile}) {
	}
`
