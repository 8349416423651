import React from 'react'
import styled from 'styled-components'
import { lighten } from 'polished'
import { rhythm } from '../utils/typography'
import { FaEnvelope } from 'react-icons/fa'
import { FaLinkedin } from 'react-icons/fa'
import { FaGithub } from 'react-icons/fa'
import { useSocials } from '../hooks/useSocials'

export default function Socials(props) {
	const socialsData = useSocials()
	return (
		<NavUl>
			<NavLi>
				<Icon color={props.color} href={`mailto:${socialsData.email}`}>
					<FaEnvelope />
				</Icon>
				<Icon color={props.color} href={socialsData.linkedin_url} target="_blank">
					<FaLinkedin />
				</Icon>
				<Icon color={props.color} href={socialsData.github_url} target="_blank">
					<FaGithub />
				</Icon>
			</NavLi>
		</NavUl>
	)
}

const NavUl = styled.ul`
	margin: 0;
	display: flex;
`

const NavLi = styled.li`
	margin: 0;
	display: flex;
	align-items: center;
	list-style-type: none;
`

const Icon = styled.a`
	cursor: pointer;
	margin-right: ${rhythm(1)};
	text-decoration: none;
	color: ${props => (props.color === 'light' ? props.theme.colors.primary : props.theme.colors.secondary)};
	transition: margin 0.2s;

	:hover {
		color: ${props =>
			lighten(0.2, props.color === 'light' ? props.theme.colors.primary : props.theme.colors.secondary)};
		margin-top: -1rem;
	}
`
