import styled from 'styled-components'

const Rectangle = styled.div`
	background-color: ${props => props.theme.colors.secondary};
	z-index: -100;
	border-radius: 30px;
	box-shadow: 0px 0px 10px 1px #000000a8;
`

export default Rectangle
