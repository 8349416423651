import { createGlobalStyle } from 'styled-components'
import '../fonts/fontFaces.css'

const GlobalStyle = createGlobalStyle`
	html {
		overflow-x: hidden;
	}

  body {
		background-color: ${props => props.theme.colors.primary};;
		color: #000;
		overflow-x: hidden;
		position: relative;
	}

	img {
    max-width: 100%;
    max-height: 100%;
	}

	a {
		text-decoration: none;
	}
`

export default GlobalStyle
